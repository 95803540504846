<template>
  <div class="n-card">
    <div class="n-card__image">
      <BaseIcon
        :glyph="icon"
        class="n-card__image-icon"
        size="1em" />
    </div>
    <div class="n-card__data">
      <div class="n-card__title text-size-h4">
        <slot name="title">Уведомление #{{ id }}</slot
        >&nbsp;<AppTag
          v-if="isNew"
          class="n-card__tag"
          >Новое</AppTag
        >
      </div>
      <div class="n-card__text text-size-xs color-meta">
        <slot></slot>
      </div>
    </div>
    <div class="n-card__controls">
      <button
        title="Удалить сообщение"
        class="n-card__remove"
        @click.prevent="handleDelete">
        <BaseIcon glyph="close" />
      </button>
      <div
        v-if="formatedDate"
        class="text-size-xs color-meta">
        <span class="text-no-wrap">{{ formatedDate }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  MSG_TYPE_EVENT,
  MSG_TYPE_MENTOR,
  MSG_TYPE_ONTI,
  MSG_STATUS_DELIVERED,
  MSG_STATUS_SENT,
  MSG_STATUS_DELETED,
} from "@/constants/notifications";
import dayjs from "@/plugins/dayjs";
import { delay } from "@/utils";
import AppTag from "@/components/AppTag.vue";
export default {
  name: "NotificationCard",
  components: { AppTag },
  props: {
    id: {
      type: Number,
      required: true,
    },
    date: {
      type: String,
    },
    type: {
      type: String,
    },
    status: {
      type: String,
    },
    isNew: {
      type: Boolean,
    },
  },
  computed: {
    formatedDate() {
      const { date } = this;
      if (!date) return;
      return dayjs().to(date);
    },
    icon() {
      const { type } = this;
      switch (type) {
        case MSG_TYPE_MENTOR:
          return "whistle";
        case MSG_TYPE_EVENT:
          return "star";
        case MSG_TYPE_ONTI:
          return "brand";
        default:
          return "connect";
      }
    },
  },
  mounted() {
    this.updateInitialStatus();
  },
  methods: {
    updateInitialStatus() {
      const { status, id } = this;
      if (![MSG_STATUS_SENT, MSG_STATUS_DELIVERED].includes(status)) {
        return;
      }
      delay(5000).then(() => {
        this.$emit("addToList", id);
      });
    },
    handleDelete() {
      this.setStatus(MSG_STATUS_DELETED);
    },
    setStatus(status) {
      const { id } = this;
      this.$emit("setStatus", {
        id,
        status,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.n-card {
  padding: 18px 24px;
  background-color: #fff;
  border-radius: @radius-m;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &__remove {
    box-shadow: none;
    border: 0;

    cursor: pointer;
    background-color: transparent;
    font-size: 18px;
    padding: 3px;
    color: @meta-color;
    margin-right: -6px;

    &:hover {
      color: @link-color;
    }
  }

  &__image {
    width: 40px;
    height: 40px;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: @bg-light;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: fade(@base-color, 30%);
  }

  &__controls {
    flex-grow: 0;
    text-align: right;
  }

  &__data {
    min-width: 1px;
    flex-grow: 1;
    margin-right: 14px;
    overflow: hidden;
  }

  &__tag {
    .fz(12);
    // vertical-align: super;
    vertical-align: baseline;
    font-weight: normal;
  }

  &__title {
    font-weight: bold;
    margin-top: 0.3em;
  }

  // prettier-ignore
  .media-max-xs ({
    display: block;
    position: relative;
    &__image {
      margin-bottom: 10px;
    }

    &__controls {
      text-align: left;
      margin-top: 10px;
    }

    &__remove {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  });
}
</style>
